






import {Vue, Component} from 'vue-property-decorator';
import Menu from '@/components/Restaurant/Menu.vue';


@Component({
    components: {
        Menu
    }
})
export default class extends Vue {

}
